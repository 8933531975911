import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppStyle from "../App.module.css";

const PricingPage: React.FC = () => {
  const policies = [
    {
      title: "Age Restriction",
      description:
        "In accordance with safety, sanitation, and tattoo licensing regulations, we are unable to admit anyone under the age of 18 into the clinic.",
    },
    {
      title: "Cancellation Policy",
      description:
        "Cancellations within 24 hours or missed appointments will incur a charge of 100% of the treatment cost, which must be settled before booking a new appointment.",
    },
    {
      title: "Deposit Requirement",
      description:
        "A £50 deposit is required to secure your booking. This deposit is non-refundable but may be transferable if sufficient notice is given for cancellation.",
    },
    {
      title: "Appointment Changes",
      description:
        "Please make every effort to notify us of any changes to your appointment at least 48 hours in advance.",
    },
  ];

  const micropigmentationConditions = [
    {
      title: "Micropigmentation Conditions",
      description:
        "You cannot receive PMU if any of the following apply: Under the age of 18, experiencing skin conditions in the treatment area, such as eczema, psoriasis, dermatitis, or sun damage, pregnant, breastfeeding, regular prescribed blood thinners, under the influence of alcohol and/or drugs, prone to keloid scarring, or have certain allergies.",
    },
    {
      title: "Consent Requirements",
      description:
        "You will need a letter of consent from your GP if any of the following apply: diabetes, blood pressure issues, heart conditions, currently receiving cancer treatment or have done so within the last 6 months, epilepsy or seizures, haemophilia, or hepatitis.",
    },
  ];

  return (
    <Container className={AppStyle.MainContainer}>
      <Container style={{ padding: "0 20%" }}>
        <Row className="justify-content-md-center">
          <Col>
            {" "}
            <h2>PMU Price List</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <h6>Packages</h6>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={6}>
            <h6>PMU BROWS</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>INITIAL TREATMENT</p>
          </Col>
          <Col>
            <p>£145</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>TOP UP (4-6 WEEKS)</p>
          </Col>
          <Col>
            <p>£100</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>ANNUAL COLOUR BOOST (EXISTING CLIENTS ONLY)</p>
          </Col>
          <Col>
            <p>£145</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={6}>
            <h6>PMU LIP BLUSH</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>INITIAL TREATMENT</p>
          </Col>
          <Col>
            <p>£130</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>TOP UP (4-6 WEEKS)</p>
          </Col>
          <Col>
            <p>£100</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>ANNUAL COLOUR BOOST (EXISTING CLIENTS ONLY)</p>
          </Col>
          <Col>
            <p>£130</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={6}>
            <h6>BROW AND LIP PACKAGE</h6>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>INITIAL TREATMENT</p>
          </Col>
          <Col>
            <p>£200</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>TOP UP (6-8 WEEKS)</p>
          </Col>
          <Col>
            <p>£200</p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            {policies.map((policy, index) => (
              <div key={index} className="mb-4">
                <h2>{policy.title}</h2>
                <p>{policy.description}</p>
              </div>
            ))}

            {micropigmentationConditions.map((item, index) => (
              <div key={index} className="mb-4">
                <h2>{item.title}</h2>
                <p>{item.description}</p>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default PricingPage;

// <Row>
//   <Col sm={8}>
//     <h3>Name of package</h3>
//   </Col>
// </Row>
// <Row>
//   <Col sm={8}>
//     <p>Item</p>
//   </Col>
//   <Col sm={3}>
//     <p>£Price</p>
//   </Col>
// </Row>

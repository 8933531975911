import React from "react";
import "./App.module.css";
import { Route, Routes } from "react-router-dom";
import NavBarComponent from "./components/NavBarComponent";
import HomePage from "./pages/HomePage";
import FooterComponent from "./components/FooterComponent";
import AboutPage from "./pages/AboutPage";

import PricingPage from "./pages/PricingPage";
import PreCarePage from "./pages/PreCarePage";

function App() {
  return (
    <div className="App">
      <NavBarComponent />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/pre-care" element={<PreCarePage />} />
      </Routes>
      <FooterComponent />
    </div>
  );
}

export default App;

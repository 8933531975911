import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppStyle from "../App.module.css";
import ImageComponent from "../components/ImageComponent";
const AboutPage: React.FC = () => {
  return (
    <Container className={AppStyle.MainContainer}>
      <Row className="justify-content-md-center">
        <Col>
          <h2>About Me</h2>
        </Col>
      </Row>
      <Row className="justify-content-md-center">
        <Col md={6}>
          {" "}
          <ImageComponent image={8} size={80} />
        </Col>
        <Col md={6} style={{ lineHeight: "3.5" }}>
          {" "}
          <p>
            I am currently a Registered Nurse with the NMC for the past 4 years,
            I myself have been getting Permanent Make up for the last 8 years,
            this is where my interest began for PMU. I loved how my artist
            managed to transform my Eyebrows giving me more confidence in myself
            and for them to look so natural! I wanted to create this feeling for
            other individuals. I finally bit the bullet and trained in Permanent
            Make-up, Brows and Lip Blush. This was the best decision I could
            have made making individuals feel and look their best by enhancing
            their natural brows and lips to a more desirable look.
          </p>
        </Col>
      </Row>
      <br />
      <Row>
        <Col>
          <h4>Certification</h4>
        </Col>
      </Row>
      <br />
      <Row>
        <ImageComponent image={11} size={80} />
      </Row>
      <br />
      <Row>
        <ImageComponent image={12} size={80} />
      </Row>
      <br />
    </Container>
  );
};

export default AboutPage;

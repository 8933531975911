import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppStyle from "../App.module.css";
import ImageComponent from "../components/ImageComponent";
const PreCarePage: React.FC = () => {
  return (
    <Container className={AppStyle.MainContainer}>
      <Row className="justify-content-md-center">
        <Col>
          <h2>Pre-Care</h2>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="d-flex align-items-center">
          <ImageComponent image={9} size={60} />
        </Col>
        <Col md={6}>
          <br />
          <h2>Eyebrow Pre Care</h2>
          <ul>
            <p>
              <li>
                It is important to avoid ibuprofen, aspirin, and other
                blood-thinning medications prior to your treatment. If you
                regularly use these, please consult your healthcare provider for
                guidance.
              </li>
              <li>
                Please refrain from having any cosmetic injections for at least
                2 weeks before your treatment, as Botox can impact the placement
                of your eyebrows.
              </li>
              <li>
                Please schedule any brow maintenance appointments, such as
                waxing or tinting, 1-2 weeks prior to your treatment. (On the
                day of the treatment, I will use threading to achieve your
                desired shape.)
              </li>
              <li>
                Please avoid using skin care products that contain any of the
                following ingredients 1-2 weeks prior to your treatment:
                retinols, AHAs, and vitamins A, C, and E.
              </li>
            </p>
          </ul>
        </Col>
      </Row>
      <br />
      <Row>
        <Col md={6} className="d-flex align-items-center">
          <ImageComponent image={10} size={60} />
          <br />
        </Col>
        <Col md={6}>
          <br />
          <h2>Lip Blush Pre Care</h2>
          <br />
          <ul>
            <p>
              <li>
                If you have ever experienced a cold sore, there is a significant
                risk that the Lip Blush treatment could trigger an outbreak.
                This can also occur in individuals who have never had a cold
                sore before. Therefore, I strongly advise all clients receiving
                Lip Blush to take the prescription medication Acyclovir for 5
                days prior to and 5 days following the treatment. You can obtain
                this from your GP or a local pharmacy.
              </li>
              <li>
                Please refrain from consuming alcohol or caffeine for 24 hours
                prior to your treatment.
              </li>
              <li>
                Avoid biting off any dead skin from your lips. Instead, gently
                exfoliate any dry, dead skin before your treatment. Use a
                moisturizing lip balm regularly, or apply a lip scrub as needed.
              </li>
              <li>
                Ensure your lips are well-hydrated for 1-2 weeks leading up to
                your treatment.
              </li>
              <li>
                If you have a specific lipstick color in mind, please bring it
                to your appointment, and we will try to match it as closely as
                possible.
              </li>
            </p>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default PreCarePage;

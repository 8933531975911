/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import ImageComponent from "../components/ImageComponent";
import AppStyle from "../App.module.css";

const HomePage: React.FC = () => {
  return (
    <Container className={AppStyle.MainContainer}>
      <Container>
        <br />
        <Row>
          <Col md={6}>
            <ImageComponent image={3} size={60} />
          </Col>
          <br />
          <Col md={6}>
            <p>
              Welcome to Shannon Clark Cosmetics, where beauty meets expertise.
              Founded by Shannon Clark, our mission is to provide top-tier
              permanent makeup and aesthetic services, helping every client feel
              confident, empowered, and radiant. At Shannon Clark Cosmetics, we
              know that beauty goes beyond the surface. It’s about embracing
              your unique features and exuding confidence. Shannon’s passion for
              enhancing natural beauty and her commitment to excellence ensure
              outstanding results.
            </p>
            <p>
              We offer a variety of services, including expertly shaped
              eyebrows, beautiful lip blush, and rejuvenating skin treatments,
              all designed to fulfill your aesthetic desires. Whether you're
              looking for subtle enhancements or a dramatic change, we can bring
              your vision to life. Client satisfaction is essential to us. We
              take the time to understand your goals and personalize our
              services with precision and care, ensuring you leave with stunning
              results every time.
            </p>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <h2>Our Services</h2>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xl>
            <ImageComponent image={4} size={60} />
            <br />
            <p>
              <strong>PMU Brows</strong>
            </p>
            <p>
              Each set of brows are designed to suit the client. Decide if you
              want full hair stroke natural and fluffy, a soft powdered brow or
              maybe a combination of both, we can tailor the design to each
              client’s preference.
            </p>
          </Col>
          <Col xl>
            <ImageComponent image={5} size={60} />
            <br />
            <p>
              <strong>Aesthetics</strong>
            </p>
            <p>
              We specialises in natural enhancements and small tweakments that
              make a huge difference to the client’s confidence. Our most
              popular treatments are Anti-Wrinkle injections, skin boosters and
              facial dermal filler.
            </p>
          </Col>
          <Col xl>
            <ImageComponent image={6} size={45} />
            <br />
            <p>
              <strong>PMU Lips</strong>
            </p>
            <p>
              Lip blush is perfect for clients who have lost pigment and lacking
              shape and definition. Lip blush works well with lip filler to
              define the shape or as an alternate to lip filler, giving you that
              fuller look without adding filler.
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Follow me for updates and offers</h3>
          </Col>
        </Row>
        <br />
        <Row className="justify-content-md-center">
          <Col xl={1}>
            <strong>
              <a
                href="https://www.facebook.com/people/Shannonclarkpermanentcosmetics/61555906024866/"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className="fa-brands fa-facebook fa-2xl"
                  style={{ color: "#74C0FC" }}
                ></i>
              </a>
            </strong>
          </Col>
          <Col xl={1}>
            <strong>
              <a
                href="https://www.instagram.com/shannonclark.cosmetics?utm_source=qr"
                target="_blank"
                rel="noreferrer"
              >
                {" "}
                <i
                  className="fa-brands fa-instagram fa-2xl"
                  style={{ color: "#f74040" }}
                ></i>
              </a>
            </strong>
          </Col>
          <Col xl={1}>
            <a href="/" target="_blank" rel="noreferrer">
              {" "}
              <i className="fa-brands fa-tiktok fa-2xl"></i>
            </a>
          </Col>
        </Row>
        <br />
      </Container>
    </Container>
  );
};

export default HomePage;

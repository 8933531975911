import React from "react";
import { Col, Container, Row } from "react-bootstrap";

const FooterComponent: React.FC = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container fluid>
        <br />
        <Row>
          <Col>
            <p>Copyright &copy; {currentYear}, Shannon Clark</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <p>
              <strong>
                <a
                  href="https://www.facebook.com/people/Shannonclarkpermanentcosmetics/61555906024866/"
                  target="_blank"
                  rel="noreferrer"
                >
                  {" "}
                  <i
                    className="fa-brands fa-facebook fa-2xl"
                    style={{ color: "#74C0FC" }}
                  ></i>
                </a>
              </strong>
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default FooterComponent;

import React from "react";
import { Nav, Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import ImageComponent from "../components/ImageComponent";

const NavBarComponent: React.FC = () => {
  return (
    <Container
      fluid
      style={{
        borderBottom: "1px solid #a3a3a3",
        paddingBottom: "1px",
        marginBottom: "20px",
        paddingTop: "1px",
        backgroundColor: "#e4dcd1",
      }}
    >
      <Row className="justify-content-md-center">
        <ImageComponent image={7} size={10} />
      </Row>
      <Row className="d-flex justify-content-center">
        <Col xs="auto">
          <Nav.Link to="/" as={NavLink} style={{ padding: 0 }}>
            Home
          </Nav.Link>
        </Col>
        <Col xs="auto">
          <Nav.Link to="/about" as={NavLink} style={{ padding: 0 }}>
            About
          </Nav.Link>
        </Col>
        <Col xs="auto">
          <Nav.Link to="/pricing" as={NavLink} style={{ padding: 0 }}>
            Pricing / Policies
          </Nav.Link>
        </Col>
        <Col xs="auto">
          <Nav.Link to="/pre-care" as={NavLink} style={{ padding: 0 }}>
            Pre-Care
          </Nav.Link>
        </Col>
      </Row>
    </Container>
  );
};

export default NavBarComponent;

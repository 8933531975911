/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { Col } from "react-bootstrap";
import images from "../images.json";

type ImageProps = {
  id?: number;
  image: number;
  url?: string;
  title?: string;
  full?: boolean;
  banner?: boolean;
  size?: number;
  radius?: boolean;
};

const ImageComponent: React.FC<ImageProps> = ({
  full,
  image,
  size,
  radius,
}) => {
  const imageUrl = images.images[image]?.url;

  return (
    <Col xs={12} className="d-flex justify-content-center">
      <img
        src={imageUrl}
        style={{
          width: `${size}%`,
          height: "auto",
          objectFit: "cover",
          borderRadius: "5%",
        }}
        alt="Description" // Optional, to improve accessibility
      />
    </Col>
  );
};

export default ImageComponent;
